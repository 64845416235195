<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <el-button type="primary" size="small" @click="Add()" plain>新增</el-button>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="itemName" label="名称"> </el-table-column>
          <el-table-column prop="itemType" label="分类" sortable>
            <template slot-scope="scope">
              <span v-if="scope.row.itemType == 0">检查项目</span>
              <span v-if="scope.row.itemType == -1">其他</span>
              <span v-if="scope.row.itemType == 4">代煎费</span>
              <span v-if="scope.row.itemType == 6">快递费</span>
              <span v-if="scope.row.itemType == 7">包装费</span>
            </template>
          </el-table-column>
          <el-table-column prop="marketPrice" label="成本价" align="center">
            <template slot-scope="scope">
              {{ scope.row.marketPrice.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="tradingPrice" label="销售价" align="center">
            <template slot-scope="scope">
              {{ scope.row.tradingPrice.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="EditBtn(scope.row)">编辑</span>
              <span class="danger optionBtn" @click="RemoveBtn(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <el-dialog :title="DialogTitle" :visible.sync="setDialog" width="30vw">
      <el-form class="dialog-form" :model="form" ref="form" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="分类：">
          <el-select v-model="form.itemType" placeholder="请选择" size="small" @change="selectItem" :disabled="form.id>0">
            <el-option v-for="item in itemOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <template v-if="form.itemType == 0">
          <el-form-item label="名称：" prop="itemName">
            <el-input v-model="form.itemName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="成本价：￥" prop="marketPrice">
            <el-input-number v-model="form.marketPrice" :precision="2" :step="1" :min="0" controls-position="right"></el-input-number>
          </el-form-item>
        </template>
        <el-form-item label="价格：￥" prop="tradingPrice">
          <el-input-number v-model="form.tradingPrice" :precision="2" :step="1" :min="0" controls-position="right"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('form')">取 消</el-button>
        <el-button type="primary" @click="SaveBtn('form')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient.js'
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      oranizationId: null,
      UserId: null,
      tableData: [],
      keyWord: '',
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      setDialog: false,
      DialogTitle: '新增收费项目',
      form: {
        itemName: '',
        itemType: '0',
        marketPrice: '',
        tradingPrice: '',
      },
      formLabelWidth: '120px',
      rules: {
        itemName: [{ required: true, message: '请输入收费项目', trigger: 'blur' }],
        marketPrice: [{ required: true, message: '请输入成本价', trigger: 'blur' }],
        tradingPrice: [{ required: true, message: '请输入销售价', trigger: 'blur' }],
      },

      itemOption: [
        {
          value: '0',
          label: '检查项目',
        },
        {
          value: '4',
          label: '代煎费',
        },
        {
          value: '6',
          label: '快递费',
        },
        {
          value: '-1',
          label: '其他',
        },
        // {
        //   value: '7',
        //   label: '包装费',
        // },
      ],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    selectItem(e) {
      console.log(e)
      switch (e) {
        case '-1':
          this.form.itemName = '其他'
          break
        case '4':
          this.form.itemName = '代煎费'
          break
        case '6':
          this.form.itemName = '快递费'
          break
        case '7':
          this.form.itemName = '包装费'
          break
      }
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList() {
      var _this = this
      _this.OutpatientDomain.CheckItemList(
        this.keyWord,
        this.pageIndex,
        -999,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Add() {
      var _this = this
      _this.setDialog = true
      _this.DialogTitle = '新增收费项目'
      _this.form = {
        id: 0,
        itemType: '0',
        itemName: '',
        marketPrice: '',
        tradingPrice: '',
      }
    },
    EditBtn(e) {
      this.setDialog = true
      this.DialogTitle = '编辑收费项目'
      this.form = {
        id: e.id,
        itemType: e.itemType.toString(),
        itemName: e.itemName,
        marketPrice: e.marketPrice,
        tradingPrice: e.tradingPrice,
      }
    },
    SaveBtn(formName) {
      var _this = this
      _this.$refs[formName].validate((res) => {
        if (res) {
          if (this.form.id > 0) {
            _this.OutpatientDomain.EditCheckItem(
              _this.form,
              function (data) {
                _this.getList()
                _this.$message({
                  type: 'success',
                  message: '修改成功!',
                })
                _this.setDialog = false
              },
              function (err) {
                console.log(err)
              }
            )
          } else {
            _this.OutpatientDomain.AddCheckItem(
              _this.form,
              function (data) {
                _this.getList()
                _this.$message({
                  type: 'success',
                  message: '添加成功!',
                })
                _this.setDialog = false
              },
              function (err) {
                var jsonObj = JSON.parse(err.msg)
                if (jsonObj.code == '400') {
                  _this.$message({
                    type: 'error',
                    message: jsonObj.msg,
                  })
                }
              }
            )
          }
        }
      })
    },
    RemoveBtn(e) {
      console.log(e)
      var _this = this
      _this
        .$confirm('是否删除此信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          // type: 'warning'
        })
        .then(() => {
          _this.OutpatientDomain.RemoveCheckItem(
            e.id,
            function (data) {
              _this.$message({
                type: 'success',
                message: '删除成功!',
              })
              _this.getList()
            },
            function (err) {
              console.log(err)
            }
          )
        })
        .catch(() => {})
    },
    CloseBtn(formName) {
      this.setDialog = false
      this.$refs[formName].resetFields()
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 80%;
  margin: 0 auto;
}
.table-top {
  margin-bottom: 15px;
}
</style>
